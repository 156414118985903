import React from "react";
import { Helmet } from "react-helmet";
import BlogPage from "../components/BlogPage";
import Layout from "../components/Layout";
import Page from "../components/Page";
import useStoryblok from "../utils/storyblok";

export default function PageIndex({ pageContext, location }) {
  const story = useStoryblok(pageContext.story, location);

  const space = pageContext.space;

  const slug = story.full_slug;

  const PageComponent = {
    page: Page,
    blog_post: BlogPage,
  }[story.content.component];

  return (
    <>
      <Helmet>
        <script
          src="https://kit.fontawesome.com/808d27dcdb.js"
          defer
          crossOrigin="anonymous"
        />
      </Helmet>
      <Layout location={location}>
        <PageComponent
          story={story}
          space={space}
          location={location}
          slug={slug}
        />
      </Layout>
    </>
  );
}
